import { inject, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@jsverse/transloco';

@Injectable()
export class IcpMatPaginatorIntl extends MatPaginatorIntl {
    private transloco = inject(TranslocoService);

    constructor() {
        super();
        this.transloco.langChanges$.subscribe(() => {
            this.firstPageLabel = this.transloco.translate('FIRST_PAGE');
            this.lastPageLabel = this.transloco.translate('LAST_PAGE');
            this.nextPageLabel = this.transloco.translate('NEXT_PAGE');
            this.previousPageLabel = this.transloco.translate('PREVIOUS_PAGE');
            this.itemsPerPageLabel = this.transloco.translate('ITEMS_PER_PAGE');
        });
    }

    override getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return '';
        }

        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return this.transloco.translate('PAGE_X_OF_Y', { start: startIndex + 1, end: endIndex, total: length });
    };
}
