import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { Routes } from '@angular/router';

import { loggedInGuard, loggedOutGuard } from './auth/guards/auth-guards';

export const routes: Routes = [
    {
        path: 'auth',
        canActivate: [loggedOutGuard],
        providers: [
            {
                provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
                useValue: { floatLabel: 'always', appearance: 'fill' } satisfies MatFormFieldDefaultOptions,
            },
        ],
        loadChildren: () => import('./auth/auth.routes'),
    },
    {
        path: 'pages',
        canMatch: [loggedInGuard],
        loadChildren: () => import('./pages/pages.routes'),
    },
    { path: 'landing', loadChildren: () => import('./landing/landing.routes') },
    { path: '', redirectTo: 'pages', pathMatch: 'full' },
];
