import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { combineLoadingSelectors } from '@icp/angular/ngrx';
import { ThemingService } from '@icp/angular/theming';
import { Store } from '@ngrx/store';

import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import {
    selectAvailableRolesLoading,
    selectDashboardEconomyLoading,
    selectDashboardEconomyTheme,
} from './state/shared/shared.selectors';

@Component({
    selector: 'icp-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [LoadingSpinnerComponent, RouterOutlet],
})
export class AppComponent {
    private store = inject(Store);
    showLoading = combineLoadingSelectors(this.store, selectDashboardEconomyLoading, selectAvailableRolesLoading);
    private theme = this.store.selectSignal(selectDashboardEconomyTheme);
    private themingService = inject(ThemingService);

    constructor() {
        effect(() => this.themingService.setupTheme(this.theme()), { allowSignalWrites: true });
    }
}
