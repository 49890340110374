import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class PageTitleStrategy extends TitleStrategy {
    private title = inject(Title);
    private transloco = inject(TranslocoService);

    override updateTitle(routerState: RouterStateSnapshot) {
        const title = this.buildTitle(routerState);
        if (title !== undefined) {
            this.title.setTitle(this.transloco.translate(`${title}`));
        } else {
            this.title.setTitle(this.transloco.translate('OTZY_DASHBOARD'));
        }
    }
}
