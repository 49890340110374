import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { selectStateDone } from '@icp/angular/ngrx';
import { Store } from '@ngrx/store';
import { filter, first, map, of, switchMap } from 'rxjs';

import { DefaultRouteService } from '../../core/services';
import { authFeature } from '../store/auth.feature';

export const loggedInGuard: CanMatchFn = () => {
    const router = inject(Router);
    return inject(Store)
        .select(authFeature.selectUser)
        .pipe(
            filter(selectStateDone),
            first(),
            map((state) => (!state.result ? router.createUrlTree(['/landing']) : true)),
        );
};

export const loggedOutGuard: CanMatchFn = () => {
    const defaultRouteService = inject(DefaultRouteService);
    return inject(Store)
        .select(authFeature.selectUser)
        .pipe(
            filter(selectStateDone),
            first(),
            switchMap((state) => (state.result ? defaultRouteService.getDefaultRouteTree().pipe(first()) : of(true))),
        );
};
